.help-center-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  margin-top: 100px;
  padding-left: 130px;
}

.help-center-container .faq {
  margin-bottom: 20px;
}

.help-center-container .faq-question {
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 5px;
}

.help-center-container .faq-answer {
  display: none;
}
