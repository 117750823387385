/* Dashboard.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  background-color: rgb(146, 181, 245);
}

.logo {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

nav {
  background-color: white;
  width: 90px;
  /* padding: 20px; */
  position: fixed;
  top: 80px;
  bottom: 0;
  /* overflow-y: auto; */
}

.side_navbar a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #130c0c;
  background-color: rgb(146, 181, 245);
}

.side_navbar a:hover {
  background-color: white;
}

.dashboard-container .container {
  display: flex;
  margin-top: 80px;
  padding-left: 130px;
}

.dashboard-container .dashboard-content {
  padding: 20px;
  width: calc(100% - 130px);
}

.dashboard-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.dashboard-container .dashboard-content img {
  max-width: 400%;
  height: 20%;
  max-height: 580px;
  display: block;
  margin: 10%;
  filter: brightness(80%);
}
