.register-wrapper * {
  margin: 0;
  padding: 0;
}
.register-main {
  display: flex;
  justify-content: center;
}

.register-wrapper div.register-main {
  width: 400px;
  margin: 100px auto 0px auto;
}

.register-wrapper h2 {
  text-align: center;
  padding: 20px;
  font-family: sans-serif;
}

.register-wrapper div.register {
  background-color: rgba(0, 0, 0, 0.5);
  /* width: 100%; */
  font-size: 18px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.register-wrapper form#register {
  margin: 40px;
}

.register-wrapper label {
  font-family: sans-serif;
  font-size: 18px;
  font-style: italic;
}

.register-wrapper input {
  width: 300px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: 0;
  padding: 7px;
  background-color: #fff;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.register-wrapper input#submit {
  width: 200px;
  padding: 7px;
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  background-color: rgba(250, 100, 0, 0.8);
  color: #fff;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
}

.register-wrapper label,
.register-wrapper span,
.register-wrapper h2 {
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
