body {
  /* margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  background-color: #e9effa !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 20px;
  background-color: rgb(146, 181, 245);
}

header {
  height: 100px;
  background-color: white;
}

nav {
  background-color: white;
  width: 110px;
  /* padding: 20px; */
  position: fixed;
  top: 80px;
  bottom: 0;
  overflow-y: auto;
}

.side_navbar a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #130c0c;
  background-color: rgb(146, 181, 245);
}

.side_navbar a:hover {
  background-color: white;
}

nav {
  background-color: white;
  width: 90px;
  /* padding: 20px; */
  position: fixed;
  top: 80px;
  bottom: 0;
  /* overflow-y: auto; */
}

.side_navbar a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #130c0c;
  background-color: rgb(146, 181, 245);
}

.side_navbar a:hover {
  background-color: white;
}
