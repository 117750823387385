Reset some default styling * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f3f3f3;
}

.chat-container {
  width: 800px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.chat-container .chat-box {
  padding: 20px;
  overflow-y: auto;
  max-height: 300px;
}

.chat-container .message-wrapper-received {
  display: flex;
  justify-content: start;
}
.chat-container .message-wrapper-sent {
  display: flex;
  justify-content: end;
}

.chat-container .message {
  margin-bottom: 10px;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 70%;
}

.chat-container .received {
  background-color: #e0e0e0;
  align-self: flex-start;
}

.chat-container .sent {
  background-color: rgb(146, 181, 245);
  align-self: flex-end;
  color: #fff;
}

.chat-container .input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-container .message-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
}

.chat-container .send-btn {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  background-color: rgb(146, 181, 245);
  color: #fff;
  cursor: pointer;
  outline: none;
}

.chat-container .send-btn:hover {
  background-color: #689f38;
}
