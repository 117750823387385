body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  /* HELLO */
  /* background-image: url("C:\\Users\\HP\\Downloads\\scg-canvas-background-animation-5.jpg"); */
  /* background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.login-container {
  display: flex;
  justify-content: center;
}

.login-container h1 {
  color: #333;
  margin-bottom: 20px;
}

.input-field {
  position: relative;
  margin-bottom: 20px;
}

.input-field input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-bottom: 2px solid #2691d9;
  background: transparent;
  outline: none;
  transition: border-color 0.3s;
}

.input-field input:focus {
  border-color: #0056b3;
}

.input-field label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 16px;
  color: #333;
  pointer-events: none;
  transition: top 0.3s, font-size 0.3s;
}

.input-field input:focus ~ label,
.input-field input:valid ~ label {
  top: -10px;
  font-size: 12px;
}

.forgot-password a {
  color: #2691d9;
  text-decoration: none;
}

.button {
  background-color: #2691d9;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0056b3;
}

.signup-link {
  margin-top: 20px;
  font-size: 16px;
}

.signup-link a {
  color: #2691d9;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.form-wrapper {
  margin-top: 100px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 400px;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  /* display: flex;
  justify-content: center; */
}
