.summarizer-container {
  /* text-align: center; */
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px; /* Adjusted margin-top */
}

.summarizer-container h1 {
  text-align: center;
}

.summarizer-container .container {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px;
}

.summarizer-container label {
  display: block;
  margin-bottom: 5px;
}

.summarizer-container textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  padding: 8px;
  resize: vertical;
}

.summarizer-container button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.summarizer-container button:hover {
  background-color: #0056b3;
}

.summarizer-container #summary {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
