/*  import google fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  text-decoration: none;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
body {
  background: rgb(226, 226, 226);
}
body {
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
}
header {
  height: 100px;
  background-color: white;
}
nav {
  position: sticky;
  top: 0;
  bottom: 0;
  height: 100vh;
  left: 0;
  width: 50px;
  background: #fff;
  overflow: hidden;
  transition: 1s;
}
nav:hover {
  width: 280px;
  transition: 1s;
}
.additional_links {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
}

.additional_links a {
  margin-bottom: 10px;
}

.logo {
  text-align: center;
  display: flex;
  margin: 10px 0 0 10px;
  padding-bottom: 3rem;
}

.logo img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.logo span {
  font-weight: bold;
  padding-left: 15px;
  font-size: 18px;
  text-transform: uppercase;
}

a {
  position: relative;
  width: 280px;
  font-size: 14px;
  color: rgb(83, 85, 85);
  display: table;
  padding: 10px;
}
.styles_rippleSpan__M7thf {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.login-btn {
  color: #190e0e;
  width: 60px;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}
.login-btn:hover {
  height: 30%;
  background-color: white;
}

.body * {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.body,
.body * {
  line-height: 1;
  font-family: Roboto, sans-serif;
}

.styles_startForFreeBtn__zPiz1 {
  background-color: #2c71eb;
  font-weight: 400;
  margin-right: 2px;
  font-size: 0.875rem;
}
.styles_contained__G5HT3 {
  border: none;
  padding: 4px 16px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
.styles_button__wTN8P {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: -moz-max-content;
  min-width: max-content;
  color: #fff;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: capitalize;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  text-decoration: none;
  transition: background-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.styles_container__FjkFg {
  justify-content: center;
  align-items: center;
  min-height: 120px;
}
.styles_container__FjkFg {
  display: flex;
  min-height: 320px;
}
.styles_container__FjkFg h1 {
  font-size: 45px;
}

.body * {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
scrollbar-thumb {
  background-color: #4680e7;
  border-radius: 5px;
}

nav .fas {
  position: relative;
  width: 70px;
  height: 40px;
  top: 20px;
  font-size: 20px;
  text-align: center;
}
.nav-item {
  position: relative;
  top: 12px;
  margin-left: 10px;
}
a:hover {
  background: #eee;
}
a:hover i {
  color: black;
  transition: 0.5s;
}
.logout {
  position: absolute;
  bottom: 0;
}

.dashboard-container .container {
  display: flex;
}

/* MAin Section */
.main {
  position: relative;
  padding: 20px;
  width: 100%;
}
.main-top {
  display: flex;
  width: 100%;
}
.main-top i {
  position: absolute;
  right: 0;
  margin: 10px 30px;
  color: rgb(110, 109, 109);
  cursor: pointer;
}
.main .users {
  display: flex;
  width: 100%;
}
.users .card {
  width: 25%;
  margin: 10px;
  background: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}
.users .card img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.users .card h4 {
  text-transform: uppercase;
}
.users .card p {
  font-size: 12px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.users table {
  margin: auto;
}
.users .per span {
  padding: 5px;
  border-radius: 10px;
  background: rgb(223, 223, 223);
}
.users td {
  font-size: 14px;
  padding-right: 15px;
}
.users .card button {
  width: 100%;
  margin-top: 8px;
  padding: 7px;
  cursor: pointer;
  border-radius: 10px;
  background: transparent;
  border: 1px solid #4ad489;
}
.users .card button:hover {
  background: #4ad489;
  color: #fff;
  transition: 0.5s;
}

.attendance {
  margin-top: 20px;
  text-transform: capitalize;
}
.attendance-list {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.1);
}
.table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 15px;
  min-width: 100%;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
}
table thead tr {
  color: #fff;
  background: #34af6d;
  text-align: left;
  font-weight: bold;
}
.table th,
.table td {
  padding: 12px 15px;
}
.table tbody tr {
  border-bottom: 1px solid #ddd;
}
.table tbody tr:nth-of-type(odd) {
  background: #f3f3f3;
}
.table tbody tr.active {
  font-weight: bold;
  color: #4ad489;
}
.table tbody tr:last-of-type {
  border-bottom: 2px solid #4ad489;
}
.table button {
  padding: 6px 20px;
  border-radius: 10px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #4ad489;
}
.table button:hover {
  background: #4ad489;
  color: #fff;
  transition: 0.5rem;
}
