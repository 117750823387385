body {
  font-family: Arial, sans-serif;
  /* background-color: rgb(146, 181, 245); */
}

.translator-container h1 {
  text-align: center;
}
.translator-container .container {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px;
}
.translator-container label {
  display: block;
  margin-bottom: 5px;
}
textarea {
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
  padding: 8px;
  resize: vertical;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.translator-container button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
}
.translator-container button:hover {
  background-color: #0056b3;
}
.translator-container #translation {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
.translator-container .custom-select {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.translator-container .custom-select select {
  appearance: none;
  width: 100%;
  height: 100%;
  padding: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
}
.translator-container .custom-select select:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
